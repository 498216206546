<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t('gallery') }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white;padding-top: 36px;padding-bottom: 120px;"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-12 " v-for="item in list" :key="item.id">
            <img :src="$baseUploadURL + item.image" style="width: 100%;height: 300px;margin-bottom: 16px;" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },

  created() {
    this.$http.get('gallery').then(
      (res) => {
        this.list = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  },
  head: {
    // title: {
    //   inner: 'Offers',
    // },
    // Meta tags
    meta: [
      { name: 'application-name', content: 'Kleopatra Massage & Beauty' },
      {
        name: 'description',
        content: 'Kleopatra Massage & Beauty',
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: 'twitter:title', content: 'Kleopatra Massage & Beauty' },
      // with shorthand
      {
        n: 'twitter:description',
        c: 'Kleopatra Massage & Beauty',
      },
      // ...
      // Google+ / Schema.org
      { itemprop: 'name', content: 'Kleopatra Massage & Beauty' },
      { itemprop: 'description', content: 'Kleopatra Massage & Beauty' },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: 'og:title', content: 'Kleopatra Massage & Beauty' },
      // with shorthand
      {
        p: 'og:image',
        c: 'https://kleopatramassage-beauty.com/image/logo.png',
      },
      // ...
    ],
  },
};
</script>

<style></style>
